import { useState } from "react";
import { useFetch } from "../services/fetch";
import DataTable from "./DataTable";
import { downloadAttachment } from "../services/util";
import ErrorAlert from "./ErrorAlert";
import TableFrame from "./TableFrame";
import ButtonTray, { DownloadButton } from "./ButtonTray";
import { MiniTableContainer } from "./TableContainer";
import { FormatDateTime } from "../services/util";

export default function PlateMapFilesTable() {
    const [plateMap, setPlateMap] = useState([]);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const { data, isLoading, error: fetchError } = useFetch("/instruments/interfaces/files/?type=plateMap")

    const handleDownload = () => {
        setLoading(true)
        if (!Array.isArray(data)) {
            setError("Download failed.")
            return
        }

        const selectedFile = data.filter((f) => f.ID === plateMap[0])

        if (selectedFile.length !== 1) {
            setError("Download failed.")
            return
        }

        const uuid = selectedFile[0]?.File?.UUID
        const filename = selectedFile[0]?.File?.Name

        downloadAttachment(uuid, filename, setError)
        setLoading(false)
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Name", headerName: "Name", width: 150, valueGetter: row => row?.row?.File?.Name },
        { field: "CreatedAt", headerName: "Created", width: 150, valueGetter: row => FormatDateTime(row.row.CreatedAt) },

    ];

    return (
        <TableFrame>
            <MiniTableContainer>
                <DataTable
                    data={data}
                    loading={isLoading}
                    error={fetchError}
                    columns={columns}
                    selected={plateMap}
                    setSelected={setPlateMap}
                />
            </MiniTableContainer>
            <ErrorAlert error={error} />
            <ButtonTray>
                <DownloadButton
                    onClick={handleDownload}
                    disabled={!plateMap || plateMap.length < 1}
                    loading={loading}
                />
            </ButtonTray>
        </TableFrame>
    );
}
