import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const MedicationsEdit = ({ medications, setMedications }) => {
    const [medicationList, setMedicationList] = useState([]);
    const [error, setError] = useState(null);
    const [medicationInput, setMedicationInput] = useState("");
    const [triggerGetMeds, setTriggerGetMeds] = useState(false);

    useEffect(() => {
        fetch(
            `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=${medicationInput}&df=SXDG_RXCUI,DISPLAY_NAME&maxList=25`
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load medication list.");
                }

                return p.json();
            })
            .then((p) => {
                let meds = p[3].map((row) => {
                    return { RxNormID: row[0], Name: row[1] };
                });

                setMedicationList(meds);
            })
            .catch((e) => setError(e.message));
    }, [medicationInput]);

    useEffect(() => {
        // get drug classes for selected drugs
        //
        if (medications === undefined) {
            return;
        }

        Promise.all(
            medications.map((med) => {
                if ("DrugClasses" in med) {
                    return Promise.resolve(med);
                }

                return fetch(
                    `https://rxnav.nlm.nih.gov/REST/rxclass/class/byRxcui.json?rxcui=${med.RxNormID}`
                )
                    .then((p) => {
                        if (!p.ok) {
                            throw new Error("Failed to find drug class.");
                        }

                        return p.json();
                    })
                    .then((p) => {
                        let drugClasses =
                            p?.rxclassDrugInfoList?.rxclassDrugInfo.map(
                                (row) => {
                                    return {
                                        Name: row?.rxclassMinConceptItem
                                            ?.className,
                                        RxNormID:
                                            row?.rxclassMinConceptItem?.classId,
                                    };
                                }
                            );

                        // create an array of unique RxNormID values
                        const uniqueRxNormIDs = [
                            ...new Set(
                                drugClasses.map((item) => item.RxNormID)
                            ),
                        ];

                        // create a new array containing only the first object with each unique RxNormID
                        const uniqueDrugClasses = uniqueRxNormIDs.map((id) =>
                            drugClasses.find((item) => item.RxNormID === id)
                        );

                        return {
                            ...med,
                            DrugClasses: uniqueDrugClasses,
                        };
                    });
            })
        )
            .then((medsWithDrugClasses) => {
                setMedications(medsWithDrugClasses);
            })
            .catch((e) => setError(e.message));
    }, [triggerGetMeds]);

    if (medications === undefined) {
        return <></>;
    }

    return (
        <>
            <Autocomplete
                multiple
                error={error !== null ? error : ""}
                options={medicationList}
                sx={{
                    minWidth: "223px",
                    maxWidth: "100%",
                }}
                getOptionLabel={(option) => option.Name}
                keepNonExistentRowsSelected
                isOptionEqualToValue={(option, value) =>
                    option.RxNormID === value.RxNormID
                }
                value={medications}
                onChange={(_, value) => {
                    setMedications(value);
                    setTriggerGetMeds(!triggerGetMeds);
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            size="small"
                            onChange={(e) => setMedicationInput(e.target.value)}
                            label="Medications"
                        />
                    );
                }}
            />
        </>
    );
};

export { MedicationsEdit };
