import ShipmentsTable from "./ShipmentsTable";
import {
    getIncomingShipments,
    getReceivedShipments,
    getOutgoingShipments,
    getAllShipments,
} from "../services/shipments";

export default function ShipmentsTab({ tab }) {
    let getShipments;

    console.log(tab);

    switch (tab) {
        case "incoming":
            getShipments = getIncomingShipments;
            break;
        case "received":
            getShipments = getReceivedShipments;
            break;
        case "outgoing":
            getShipments = getOutgoingShipments;
            break;
        case "all":
            getShipments = getAllShipments;
            break;
        default:
            getShipments = getIncomingShipments;
            break;
    }

    return <ShipmentsTable getShipments={getShipments} />;
}
