import React, { useState } from "react";
import ExtractionPlatesTable from "../components/ExtractionPlateTable";
import { Button, Stack } from "@mui/material";
import ExtractionPlateInput from "../components/ExtractionPlateInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { MiniTableContainer } from "./TableContainer";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";

function getTodayDateInYYYYMMDD() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hour}${min}`;
}


export default function ExtractionPlateSetup() {
    const [selectedExtractionPlate, setSelectedExtractionPlate] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [error, setError] = useState(null);

    console.log(selectedExtractionPlate)

    const reset = () => {
        setEditMode(false);
        setCreateMode(false);
        setSelectedExtractionPlate({});
    };

    const handleDownloadPlateMap = async () => {
        try {
            let uri = `/molecular/extPlate/${selectedExtractionPlate[0]}`

            const data = await apiFetch(uri, "GET", null, false, { headers: { Accept: "text/csv" } })
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${getTodayDateInYYYYMMDD()}_extraction_plate.csv`;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
            // setRefresh(!refresh);
        } catch (e) {
            setError(e.message);
        }
    };

    if (editMode) {
        return <ExtractionPlateInput id={selectedExtractionPlate[0]} reset={reset} />;
    } else if (createMode) {
        return <ExtractionPlateInput reset={reset} />;
    }

    return (
        <>
            <TableFrame>
                <MiniTableContainer>
                    <ExtractionPlatesTable
                        Title=""
                        setExtractionPlates={setSelectedExtractionPlate}
                    />
                </MiniTableContainer>
                <ErrorAlert error={error} />
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            selectedExtractionPlate.length < 1
                        }
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleDownloadPlateMap}
                        disabled={
                            Object.keys(selectedExtractionPlate).length === 0 || selectedExtractionPlate.length === 0
                        }
                        color="secondary"
                        sx={{ color: "white" }}
                    >
                        Download
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
