import * as React from "react";
import Box from "@mui/material/Box";
import ResultsTable from "../components/ResultsTable";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Molecular from "../components/Molecular";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";

export default function Results() {
    const [value, setValue] = useTabState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Results</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        <Tab label="Pending" {...a11yProps(0)} />
                        <Tab label="Microsopic" {...a11yProps(1)} disabled />
                        <Tab label="Urinalysis" {...a11yProps(2)} disabled />
                        <Tab label="Molecular" {...a11yProps(3)} />
                        <Tab label="On the Way" {...a11yProps(4)} />
                        <Tab label="Not Performed" {...a11yProps(5)} />
                        <Tab label="Validated" {...a11yProps(6)} />
                        <Tab label="Pending Approval" {...a11yProps(7)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <ResultsTable filter="pending" />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Molecular />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ResultsTable filter="waiting_on_sample" />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ResultsTable filter="tnp" disableEdit={true} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <ResultsTable filter="validated" disableEdit={true} allowUnvalidate />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <ResultsTable filter="pending_approval" disableEdit={true} />
            </TabPanel>
        </Framework>
    );
}
