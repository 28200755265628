import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getExtPlates } from "../services/molecular";
import { FormatDateTime } from "../services/util";

export default function ExtractionPlatesTable({
    extractionPlates,
    setExtractionPlates,
    checkboxes,
    usePCRColumns,
}) {
    const [allExtractionPlates, setAllExtractionPlates] = useState([]);
    useEffect(() => {
        let mounted = true;
        getExtPlates().then((p) => {
            if (mounted) {
                setAllExtractionPlates(p);
            }
        });
        return () => (mounted = false);
    }, []);

    let columns = [];

    if (usePCRColumns) {
        columns = [
            {
                field: "Position",
                headerName: "Position",
                width: 100,

                valueGetter: (row) => {
                    const i = extractionPlates.indexOf(row.id);
                    if (i > -1) {
                        return i + 1;
                    } else {
                        return "";
                    }
                },
            },
            { field: "Name", headerName: "Name", width: 150 },
            { field: "CreatedAt", headerName: "Created", width: 150 },
            {
                field: "Samples",
                headerName: "Samples",
                width: 150,
                valueGetter: (row) => row.row.Wells.length,
            },
        ];
    } else {
        columns = [
            { field: "ID", headerName: "ID", width: 45 },
            { field: "Name", headerName: "Name", width: 250 },
            { field: "CreatedAt", headerName: "Created", width: 220, valueGetter: (params) => FormatDateTime(params.row?.CreatedAt) },
            { field: "UpdatedAt", headerName: "Updated", width: 220, valueGetter: (params) => FormatDateTime(params.row?.UpdatedAt) },
            {
                field: "Samples",
                headerName: "Samples",
                width: 150,
                valueGetter: (row) => row.row.Wells.length,
            },
            { field: "CreatedBy", headerName: "Created By", width: 220, valueGetter: (params) => params.row?.CreatedBy?.Email },
        ];
    }

    return (
        <React.Fragment>
            <DataGridPro
                hideFooterRowCount
                getRowId={(row) => row.ID}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                checkboxSelection={checkboxes}
                rowSelectionModel={extractionPlates}
                onRowSelectionModelChange={setExtractionPlates}
                rows={allExtractionPlates}
                columns={columns}
            />
        </React.Fragment>
    );
}
