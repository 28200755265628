export async function getAllSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getCollectedSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/search?status=Collected`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getRejectedSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/search?status=Rejected`,
        { credentials: "include" }
    ).then((data) => data.json());
}


export async function getTransferredSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/search?status=Transferred`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getInTransitSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/search?status=In Transit`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function getReceivedSamples() {
    // uses filter to only see samples availabletoship
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/search?status=Received`,
        { credentials: "include" }
    ).then((data) => data.json());
}

export async function addSampleNote(sampleID, note) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/samples/` + sampleID, {
        method: "PUT",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ "Note": note }),
    });
}

export async function addSamples(samples) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/samples`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(samples),
    })
}

export async function updateSample(sample) {
    console.log(sample);
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/samples/` + sample.ID, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(sample),
    }).then((response) => response.json());
}

export async function rejectSample(sample_id, rejection) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/` +
        sample_id +
        "/rejection",
        {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(rejection),
        }
    )
}

export async function restoreSample(sample_id) {
    console.log("restoring", sample_id);
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/samples/` +
        sample_id +
        "/rejection",
        {
            method: "DELETE",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    );
}

export async function getInstrumentInterfaces() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/instruments/interfaces`,
        { credentials: "include" }
    );
}

export async function getInterfaceResults() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/instruments/interfaces/results`,
        { credentials: "include" }
    );
}

export async function addInterfaceResultFile(interfaceID, file, fields = {}) {
    var data = new FormData();
    data.append("File", file);
    for (let field in fields) {
        data.append(field, fields[field]);
    }

    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/instruments/interfaces/${interfaceID}`,
        {
            method: "POST",
            credentials: "include",
            body: data,
        }
    );
}

