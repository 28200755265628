export default async function getShipments() {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/shipments`, {
        credentials: "include",
    }).then((response) => response.json());
}
export async function searchShipmentBySampleID(sampleID) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/shipments/search?sampleId=` + sampleID, {
        credentials: "include",
    }).then((response) => response.json());
}


export async function getIncomingShipments() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments?filter=incoming`,
        { credentials: "include" }
    ).then((response) => response.json());
}

export async function getReceivedShipments() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments?filter=received`,
        { credentials: "include" }
    ).then((response) => response.json());
}
export async function getOutgoingShipments() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments?filter=outgoing`,
        { credentials: "include" }
    ).then((response) => response.json());
}
export async function getAllShipments() {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments`,
        { credentials: "include" }
    ).then((response) => response.json());
}
export async function getShipment(shipment_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments/` + shipment_id,
        { credentials: "include" }
    ).then((response) => response.json());
}

export async function receiveShipment(shipment_id) {
    return fetch(
        `${process.env.REACT_APP_API_PROXY}/v1/shipments/` + shipment_id,
        {
            method: "PUT",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ Status: "Received" }),
        }
    ).then((response) => response.json());
}

export async function addShipment(shipment) {
    return fetch(`${process.env.REACT_APP_API_PROXY}/v1/shipments`, {
        method: "POST",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(shipment),
    });
}
