import React, { useState, useEffect } from "react";
import PCRPlatesTable from "../components/PCRPlateTable";
import { getInstrumentInterfaces } from "../services/samples";
import { Button, Dialog, DialogContent, DialogActions, Stack, MenuItem, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import PCRPlateInput from "../components/PCRPlateInput";
import { apiFetch } from "../services/fetch";
import ButtonTray from "./ButtonTray";
import { MiniTableContainer } from "./TableContainer";
import TableFrame from "./TableFrame";

function getTodayDateInYYYYMMDD() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hour}${min}`;
}

function InterfaceDialog({ open, setOpen, setInstInterface, instInterface, download }) {
    const [interfaces, setInterfaces] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getInstrumentInterfaces()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load interfaces");
                }
                return p.json();
            })
            .then((p) => {
                setInterfaces(p);
            })
            .catch(e => setError(e.message));
    }, [])

    return (
        <Dialog open={open}>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <Typography variant="body1">Select interface to use for plate map generation.</Typography>
                    <Stack direction="row" spacing={2}>

                        <TextField
                            required
                            select
                            size="small"
                            name="Interface"
                            label="Interface"
                            sx={{ minWidth: "200px" }}
                            onChange={(v) => setInstInterface(v.target.value)}
                        >
                            {interfaces.map((value, index) => (
                                <MenuItem key={index} value={value.ID}>
                                    {value.Name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            startIcon={<SaveIcon />}
                            disabled={!instInterface}
                            color="success"
                            variant="contained"
                            onClick={() => { download(); setOpen(false) }}
                        >
                            <span>Download</span>
                        </LoadingButton>
                    </DialogActions>
                </Stack>
            </DialogContent>

        </Dialog>

    )

}

export default function PCRPlateSetup() {
    const [selectedPCRPlate, setSelectedPCRPlate] = useState({});
    const [instInterface, setInstInterface] = useState();
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [openInterfaceDialog, setOpenInterfaceDialog] = useState(false);
    const [error, setError] = useState(null);

    const reset = () => {
        setEditMode(false);
        setCreateMode(false);
        setSelectedPCRPlate({});
    };

    if (editMode) {
        return <PCRPlateInput extractionPlate={selectedPCRPlate} reset={reset} />;
    } else if (createMode) {
        return <PCRPlateInput reset={reset} />;
    }

    console.log(instInterface);

    const handleDownloadPlateMap = async () => {
        try {
            let uri = `/instruments/interfaces/${instInterface}?pcrPlate=${selectedPCRPlate.ID}`

            const data = await apiFetch(uri)
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${getTodayDateInYYYYMMDD()}_plate_map.csv`;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
            // setRefresh(!refresh);
        } catch (e) {
            setError(e.message);
        }
    };


    return (
        <>
            <InterfaceDialog
                open={openInterfaceDialog}
                setOpen={setOpenInterfaceDialog}
                setInstInterface={setInstInterface}
                instInterface={instInterface}
                download={handleDownloadPlateMap}
            />
            <TableFrame>
                <MiniTableContainer>
                    <PCRPlatesTable
                        Title=""
                        setSelectedPCRPlate={setSelectedPCRPlate}
                    />
                </MiniTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            Object.keys(selectedPCRPlate).length === 0
                        }
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        disabled={
                            Object.keys(selectedPCRPlate).length === 0
                        }
                        onClick={() => setOpenInterfaceDialog(true)}
                        color="secondary"
                        sx={{ color: "white" }}
                    >
                        Download
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
