import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";

import {
	Box,
	Stack,
	TextField,
	Autocomplete,
	Button,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
} from "@mui/material";
import { Facility, IntegrationFacilityMapping } from "../constants/types";
import ErrorAlert from "../components/ErrorAlert";

import {
	GridToolbar,
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
	useGridApiRef,
	GridRenderEditCellParams,
	DataGridPro,
} from "@mui/x-data-grid-pro";
import { Edit, Delete } from "@mui/icons-material";
import { apiFetch } from "../services/fetch";
import { Integrations } from "../constants/constants";


function FacilityMappings() {
	const apiRef = useGridApiRef()
	const initMapping = {
		FacilityID: 0,
		ExternalFacility: "",
		IntegrationID: 4,
	}

	const [refresh, setRefresh] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [facilities, setFacilities] = useState<Facility[]>([]);
	const [mappings, setMappings] = useState<IntegrationFacilityMapping[]>([]);
	const [newMappingError, setNewMappingError] = useState({});
	const [newMapping, setNewMapping] = useState<IntegrationFacilityMapping>(initMapping);

	useEffect(() => {
		const init = async () => {
			let response = await apiFetch("/integrations/mappings/facilities");
			setMappings(response);
			response = await apiFetch("/facilities");
			setFacilities(response);
		};

		init();
	}, [refresh]);


	const addMapping = () => {
		if (newMapping.FacilityID === 0) {
			setNewMappingError({
				...newMappingError,
				FacilityID: "Facility is required",
			});
			return;
		} else if (newMapping.IntegrationID === 0) {
			setNewMappingError({
				...newMappingError,
				IntegrationID: "Integration ID is required",
			});
			return;
		}


		setNewMappingError({});

		const add = async () => {
			try {
				await apiFetch(
					`/integrations/mappings/facilities`,
					'POST',
					/* @ts-ignore */
					newMapping
				);
				setError(null);
				setRefresh((refresh) => !refresh);
				setNewMapping(initMapping);
			} catch (error) {
				console.log(error)
				setError("Failed to add row");
			}
		};
		add();
	};

	const deleteRow = async (id: number) => {
		try {
			await apiFetch(`/integrations/mappings/facilities/${id}`, 'DELETE')
			setError(null);
			setRefresh(!refresh);
		} catch (e) {
			console.log(e)
			setError("Failed to delete row");
		}
	}

	const saveEdit = (newRow: any, oldRow: any) => {
		newRow.id = newRow.ID
		apiRef.current.stopRowEditMode(newRow)
		console.log('oldValues', oldRow)
		console.log('newValues', newRow)
		const updateRow = async () => {
			try {
				await apiFetch(
					`/integrations/mappings/facilities`,
					'POST',
					newRow
				);
				setError(null);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error)
				setError("Failed to update row");
			}

			return newRow;
		};

		return updateRow();
	};


	const RenderValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value, field } = params;
		const handleValueChange = (
			_: React.SyntheticEvent<Element, Event>,
			newValue: Facility | null,
		) => {
			console.log(newValue)
			console.log(field)
			apiRef.current.setEditCellValue({ id, field: "Facility", value: newValue });
			if (newValue) {
				apiRef.current.setEditCellValue({ id, field: "FacilityID", value: newValue?.ID });
			}
		};

		return (
			<Autocomplete
				options={facilities}
				getOptionLabel={(option) =>
					option.Name || String(option)
				}
				value={value}
				sx={{ minWidth: "200px", width: "100%" }}
				isOptionEqualToValue={(
					option,
					value
				) => option.Code === value}
				onChange={handleValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
					/>
				)}
			/>
		)
	}, [apiRef, facilities]);

	const getActions = useCallback((params: GridRowParams) => {
		return [
			<GridActionsCellItem disabled={apiRef.current.getRowMode(params.id) !== "view"} icon={<Edit />} onClick={() => apiRef.current.startRowEditMode({ id: params.id })} label="Edit" />,
			<GridActionsCellItem icon={<Delete />} onClick={() => deleteRow(Number(params.id))} label="Delete" />
		]
	}, [apiRef])

	const columns: GridColDef[] = useMemo(() => [
		{ field: "FacilityID", headerName: "Facility ID", editable: true, width: 100, renderEditCell: RenderValueCell },
		{ field: "ExternalFacility", headerName: "External Facility", editable: true, width: 200 },
		{ field: "IntegrationID", headerName: "Integration ID", width: 300, valueFormatter: (params) => params?.value?.Name, editable: false },
		{ field: "actions", type: "actions", getActions: getActions },
	], [RenderValueCell, getActions])


	return <>
		<Stack direction="column" spacing={2}>
			<Stack direction="row" spacing={2}>
				<FormControl>
					<InputLabel>Integration ID</InputLabel>
					<Select
						label="Integration ID"
						sx={{ minWidth: "200px" }}
						size="small"
						value={newMapping?.IntegrationID}
						onChange={(e) =>
							setNewMapping({
								...newMapping,
								IntegrationID: Number(e.target.value),
							})
						}
					>
						{Integrations.map((integration) => (
							<MenuItem value={integration.ID} key={integration.ID}>
								{integration.Name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					label="External Facility"
					size="small"
					value={newMapping?.ExternalFacility}
					onChange={(e) =>
						setNewMapping({
							...newMapping,
							ExternalFacility: e.target.value,
						})
					}
				/>
				<Autocomplete
					options={facilities}
					getOptionLabel={(option) =>
						option.Name
					}
					sx={{ minWidth: "200px" }}
					value={newMapping?.Facility}
					size="small"
					onChange={(_, newValue) =>
						setNewMapping({
							...newMapping,
							FacilityID: newValue?.ID || null,
							Facility: newValue,
						})
					}
					renderInput={(params) => (
						<TextField
							label="Facility"
							{...params} />
					)}
				/>
				<Button variant="contained" color="primary" onClick={() => addMapping()}>Add</Button>
			</Stack>
			<Box height={`calc(100vh - 425px)`}>
				<DataGridPro
					getRowId={(row) => row.ID}
					apiRef={apiRef}
					columns={columns}
					rows={mappings}
					editMode="row"
					processRowUpdate={saveEdit}
					onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					density="compact"
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
				/>
			</Box>
			<ErrorAlert error={error} />
		</Stack>
	</>;
}

export default FacilityMappings;
