import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { getPCRPlates } from "../services/molecular";
import { MiniTableContainer } from "./TableContainer";
import { FormatDateTime } from "../services/util";

export default function PCRPlatesTable(props) {
    const [extractionPlates, setPCRPlates] = useState([]);
    const [selectedPCRPlate, setSelectedPCRPlate] = useState([]);

    useEffect(() => {
        let mounted = true;
        getPCRPlates().then((p) => {
            if (mounted) {
                setPCRPlates(p);
            }
        });
        return () => (mounted = false);
    }, []);

    const extPlateName = (row, v) => {
        if (row.row[row.field] === null) {
            return ""
        }
        return row.row[row.field]["Name"]
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Name", headerName: "Name", width: 150 },
        { field: "CreatedAt", headerName: "Created", width: 150, valueGetter: row => FormatDateTime(row.row.CreatedAt) },
        { field: "EP1", headerName: "Ext Plate 1", width: 150, valueGetter: extPlateName },
        { field: "EP2", headerName: "Ext Plate 2", width: 150, valueGetter: extPlateName },
        { field: "EP3", headerName: "Ext Plate 3", width: 150, valueGetter: extPlateName },
        { field: "EP4", headerName: "Ext Plate 4", width: 150, valueGetter: extPlateName },

    ];

    return (
        <React.Fragment>
            <MiniTableContainer>
                <DataGridPro
                    hideFooterRowCount
                    getRowId={(row) => row.ID}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    rowSelectionModel={selectedPCRPlate}
                    onRowSelectionModelChange={(newSelection) => {
                        for (const f of extractionPlates) {
                            if (f.ID === newSelection[0]) {
                                setSelectedPCRPlate(newSelection);
                                props.setSelectedPCRPlate(f);
                            }
                        }
                    }}
                    rows={extractionPlates}
                    columns={columns}
                />
            </MiniTableContainer>
        </React.Fragment>
    );
}
