import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    addPCRPlate,
    updatePCRPlate,
    pcrPlateWells,
} from "../services/molecular";
import Alert from "@mui/material/Alert";
import ExtractionPlatesTable from "./ExtractionPlateTable";

export default function PCRPlateInput(props) {
    const [pcrPlate, setPCRPlate] = useState(
        props.pcrPlate === undefined ? {} : props.pcrPlate
    );
    const [error, setError] = useState();
    const [wells, setWells] = useState(new Map());
    const [extractionPlates, setExtractionPlates] = useState([]);

    const handleSave = (e) => {
        e.preventDefault();

        const w = [];

        wells.forEach((value, key, map) =>
            w.push({
                Position: key,
                Barcode: value,
            })
        );

        const f = {
            Name: pcrPlate.Name,
            EP1: extractionPlates[0],
            EP2: extractionPlates[1],
            EP3: extractionPlates[2],
            EP4: extractionPlates[3],
            Wells: w,
        };
        if (pcrPlate.ID === undefined) {
            // create new facility
            console.log("createing");
            addPCRPlate(f)
                .catch((err) => setError(err))
                .then(props.reset());
            // props.reset();
        } else {
            // update existing facility
            console.log("updating");
            updatePCRPlate(f, pcrPlate.ID);
            props.reset();
        }
    };

    const showError = () => {
        console.log(error);
        if (error) {
            return (
                <Alert sx={{ m: 2 }} severity="error">
                    {error.message}
                </Alert>
            );
        }
    };

    const setPCRPlateProp = (target, value) => {
        console.log(pcrPlate);
        setPCRPlate({ ...pcrPlate, [target]: value });
    };

    console.log(wells);

    return (
        <Box component="form">
            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                Create 384 PCR Plate
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        required
                        size="small"
                        sx={{ my: 2 }}
                        error={
                            pcrPlate.Name === undefined || pcrPlate.Name === ""
                        }
                        onBlur={(v) =>
                            setPCRPlateProp(v.target.name, v.target.value)
                        }
                        defaultValue={pcrPlate.Name}
                        name="Name"
                        label="Name"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <ExtractionPlatesTable
                extractionPlates={extractionPlates}
                setExtractionPlates={setExtractionPlates}
                checkboxes={true}
                    usePCRColumns={true}
            />
            {showError()}

            <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" sx={{ my: 2 }} onClick={props.reset}>
                    Back
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ my: 2 }}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </Stack>
        </Box>
    );
}
