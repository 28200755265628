import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import TestsPanel from "../components/TestsPanel";
import ProfilesPanel from "../components/ProfilesPanel";
import MethodsPanel from "../components/MethodsPanel";
import ReflexesPanel from "../components/ReflexesPanel";
import FeeSchedulePanel from "../components/FeeSchedulePanel";
import DepartmentPanel from "../components/DepartmentsPanel";
import LaboratoryPanel from "../components/LaboratoriesPanel";
import InsurancePanel from "../components/InsurancesPanel";
import DiagnosisPanel from "../components/DiagnosisPanel";
import DiagnosisRulesPanel from "../components/DiagnosisRulesPanel";
import Framework from "../components/Framework";
import InstrumentPanel from "../components/InstrumentsPanel";
import SpecimenTypePanel from "../components/SpecimenTypesPanel";
import { useTabState } from "../components/useTabState";
import CategoriesPanel from "../components/CategoryPanel";
import FrequencyRules from "../tables/frequencyrules";

export default function Setup({ tab }) {
    const [value, setValue] = useTabState(0);

    // const history = useHistory();

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const tabComponents = [
        <TestsPanel />,
        <ProfilesPanel />,
        <MethodsPanel />,
        <ReflexesPanel />,
        <SpecimenTypePanel />,
        <DepartmentPanel />,
        <CategoriesPanel />,
        <InstrumentPanel />,
        <LaboratoryPanel />,
        <FeeSchedulePanel />,
        <InsurancePanel />,
        <DiagnosisPanel />,
        <DiagnosisRulesPanel />,
        <FrequencyRules />
    ]

    const tabxref = {
        tests: 0,
        profiles: 1,
        methods: 2,
        reflexes: 3,
        departments: 4,
        instruments: 5,
        laboratories: 6,
        feeschedules: 7,
    };

    useEffect(() => {
        if (tabxref[tab] !== undefined) {
            setValue(tabxref[tab]);
        }
    }, [tab]);

    function LinkTab(props) {
        return (
            <Tab
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Setup</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        <LinkTab label="Tests" href="/setup/tests" />
                        <LinkTab label="Profiles" href="/setup/profiles" />
                        <Tab label="Methods" {...a11yProps(2)} />
                        <Tab label="Reflexes" {...a11yProps(3)} />
                        <Tab label="Specimen Types" {...a11yProps(4)} />
                        <Tab label="Departments" {...a11yProps(5)} />
                        <Tab label="Categories" {...a11yProps(6)} />
                        <Tab label="Instruments" {...a11yProps(7)} />
                        <Tab label="Laboratories" {...a11yProps(8)} />
                        <Tab label="Fee Schedules" {...a11yProps(9)} />
                        <Tab label="Insurances" {...a11yProps(10)} />
                        <Tab label="Diagnosis Codes" {...a11yProps(11)} />
                        <Tab label="Diagnosis Rules" {...a11yProps(12)} />
                        <Tab label="Frequency Rules" {...a11yProps(13)} />
                    </Tabs>
                </Box>
            </Box>
            {tabComponents.map((component, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {component}
                </TabPanel>
            )
            )}
        </Framework>
    );
}
