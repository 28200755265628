import React, { useState, useEffect } from "react";
import ProvidersTable from "../components/ProvidersTable";
import { Button, Stack } from "@mui/material";
import ProviderInput from "../components/ProviderInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function ProviderSetup() {
    const initValues = {
        FirstName: "",
        LastName: "",
        NPI: "",
        SignatureOnFile: false,
        Type: "",
        Facilities: [],
        Contacts: [],
    }

    const [provider, setProvider] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [initialValues, setInitialValues] = useState(initValues);

    console.log(provider)

    useEffect(() => {
        let ignore = false;

        if (createMode && !ignore) {
            setInitialValues(initValues)
            return
        }

        if (editMode && !ignore) {
            let newProv = { ...provider }

            let contacts = [];
            for (let p of provider.Contacts) {
                contacts.push(p.ID);
            }
            newProv.Contacts = contacts

            let facilities = [];
            for (let p of provider.Facilities) {
                facilities.push(p.ID);
            }
            newProv.Facilities = facilities
            setInitialValues(newProv)
            return
        }

        return () => { ignore = true }

    }, [createMode, editMode, provider]);


    const reset = () => {
        setEditMode(false);
        setCreateMode(false);
        setProvider({});
        setRefresh(!refresh);
    };


    if (editMode || createMode) {
        return (
            <ProviderInput
                initialValues={initialValues}
                setProvider={setProvider}
                reset={reset}
            />
        );
    }

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <ProvidersTable
                        refresh={refresh}
                        Title=""
                        setProvider={setProvider}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreateMode(true)}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={
                        // Object.keys(provider).length === 0
                        // }
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        // disabled={
                        // Object.keys(selectedProvider).length === 0
                        // }
                        color="error"
                    >
                        Deactivate
                    </Button>
                </ButtonTray>
            </TableFrame>
        </>
    );
}
