import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Box,
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    useMediaQuery,
    debounce
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    addExtPlate,
    updateExtPlate,
    extPlateWellsByRow,
    extPlateWellsByColumn,
    getExtPlates,
} from "../services/molecular";
import ErrorAlert from "./ErrorAlert";
import mdTheme from "../components/Theme";
import { apiFetch } from "../services/fetch";

function getTodayDateInYYYYMMDD() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hour}${min}`;
}


export default function ExtractionPlateInput({ id, reset }) {
    const initValues = {
        Name: getTodayDateInYYYYMMDD(),
        Type: "extraction-96",
        Wells: [],
    }

    const [initialValues, setInitialValues] = useState(initValues);
    const [error, setError] = useState();
    const [wells, setWells] = useState(new Map());
    const [scanByRow, setScanByRow] = useState(false);
    const [extPlateWells, setExtPlateWells] = useState(scanByRow ? extPlateWellsByRow : extPlateWellsByColumn)
    const isLargeScreen = useMediaQuery(mdTheme.breakpoints.up("xl"));
    const inputRefs = useRef([]);

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Wells: Yup.array().of(Yup.string().min(1)).min(1).required("At least one well must be assigned a sample"),
    })

    useEffect(() => {
        if (isLargeScreen) {
            setExtPlateWells(extPlateWellsByRow)
            return
        }

        if (scanByRow) {
            setExtPlateWells(extPlateWellsByRow)
        } else {
            setExtPlateWells(extPlateWellsByColumn)
        }
    }, [scanByRow, isLargeScreen])

    useEffect(() => {
        if (!id) {
            return
        }

        const getExtPlate = async () => {

            try {
                const response = await apiFetch(`/molecular/extPlate/${id}`)
                let init = {
                    Name: response?.Name,
                    Type: "extraction-96",
                    Wells: []
                }

                let wellMap = new Map();
                for (let well of response?.Wells) {
                    wellMap.set(well?.Position, well?.SampleBarcode)
                }
                setWells(wellMap)
                setInitialValues(init)

            } catch (e) {
                setError(e.message)
            }


        }

        getExtPlate()
    }, [id])

    const handleSubmit = () => {
        const w = [];

        wells.forEach((value, key, _) =>
            w.push({
                Position: key,
                Barcode: value,
            })
        );

        const postData = formik.values;
        postData.Wells = w;
        if (!id) {
            addExtPlate(postData)
                .catch((err) => setError(err))
                .then(() => reset());
        } else {
            updateExtPlate(postData, id)
                .then(() => reset());
        }
    };

    const onKeyDown = (e) => {
        const well = e.target.id;
        if (well === "name") {
            return
        }

        const wellMap = scanByRow ? extPlateWellsByRow : extPlateWellsByColumn;
        if (e.key === "Enter" || e.key === "Tab") {
            e.preventDefault();
            const index = wellMap.indexOf(well)
            const nextIndex = index + 1;
            if (nextIndex < wellMap.length) {
                const nextWell = wellMap[nextIndex];
                for (let inp of inputRefs.current) {
                    if (inp.id === nextWell) {
                        inp.focus();
                        return
                    }
                }
            } else {
                inputRefs.current[0].focus()
            }
        }
    }

    const handleScanChange = (_, v) => {
        if (v === "row") {
            setScanByRow(true)
        } else {
            setScanByRow(false)
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    const handleWellChange = (v) => {
        setWells(new Map(wells.set(v.target.id, v.target.value)));
    }

    const WellTextField = React.memo(({ well, index, value, onChange }) => {
        return (
            <TextField
                id={well}
                key={index}
                InputLabelProps={{ shrink: !!wells.get(well) }}
                inputRef={(el) => inputRefs.current[index] = el}
                size="small"
                color="primary"
                onChange={onChange}
                value={wells.get(well) || ""}
                name={well}
                label={well}
                variant="outlined"
            />
        )
    })

    return (
        <Box>
            <Typography sx={{ my: 2 }} variant="h5" gutterBottom>
                Create 96 Well Extraction Plate
            </Typography>
            <FormLabel>Scan By</FormLabel>
            <RadioGroup row defaultValue="column" onChange={handleScanChange}
            >
                <FormControlLabel key="col" value="column" control={<Radio />} label="Column" />
                <FormControlLabel key="row" value="row" control={<Radio />} label="Row" />
            </RadioGroup>
            <hr />
            <br />

            <Grid container spacing={1} columns={12} onKeyDown={onKeyDown}>
                <Grid item xs={12}>
                    <TextField
                        required
                        key="name"
                        id="name"
                        size="small"
                        onChange={formik.handleChange}
                        error={
                            formik.touched.Name &&
                            Boolean(formik.errors.Name)
                        }
                        value={formik.values.Name}
                        onBlur={formik.handleBlur}
                        helperText={
                            formik.touched.Name &&
                            formik.errors.Name
                        }
                        name="Name"
                        label="Name"
                        variant="outlined"
                    />
                </Grid>
                {extPlateWells.map((well, index) => {
                    return (
                        <Grid item xs={12} xl={1}>
                            <TextField
                                id={well}
                                key={index}
                                InputLabelProps={{ shrink: !!wells.get(well) }}
                                inputRef={(el) => inputRefs.current[index] = el}
                                size="small"
                                color="success"
                                sx={{ background: wells.get(well) ? "rgba(0, 128, 0, 0.4)" : null }}
                                variant="filled"
                                onChange={(v) => {
                                    setWells(new Map(wells.set(well, v.target.value)));
                                }}
                                value={wells.get(well) || ""}
                                name={well}
                                label={well}
                            />
                        </Grid>
                    );
                })}
            </Grid>

            <ErrorAlert error={error} />

            <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" sx={{ my: 2 }} onClick={reset}>
                    Back
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ my: 2 }}
                    disabled={Object.keys(formik.errors).length > 1}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Stack>
        </Box >
    );
}
