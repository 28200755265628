import React, { useRef } from "react";
import { Button, Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import { FormatDateTime } from "../services/util";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { FormatDate } from "../services/util";

export const ComponentToPrint = React.forwardRef((props, ref) => {

    const truncateName = (str) => {
        const MAX_LENGTH = 18
        if (str.length > MAX_LENGTH) {
            return str.slice(0, MAX_LENGTH) + "..."
        }
        return str
    }

    const samples = []

    props.orderDetails.Samples.forEach(sample => {
        for (let i = 0; i < sample?.SpecimenType?.QTY; i++) {
            samples.push({
                FirstName: props.orderDetails.Patient.FirstName,
                LastName: props.orderDetails.Patient.LastName,
                DOB: FormatDate(props.orderDetails.Patient.DOB),
                Specimen: truncateName(sample.SpecimenType.Specimen),
                Container: truncateName(sample.SpecimenType.Container),
                Collected: FormatDateTime(sample.DateCollected),
                Barcode: sample.Barcode,
            })
        }
    })

    return (
        <div
            ref={ref}
            className="print-container"
            style={{ margin: "0", padding: "0" }}
        >
            {samples.map((sample, i) => (
                <>
                    {i !== 0 && <div className="page-break" />}

                    <Box id="labelsize" width="2.25in" height="1.25in" p={1}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="body1"
                                align="left"
                                fontSize={10}
                                m={0}
                            >
                                {sample.LastName}, {sample.FirstName}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                                fontSize={10}
                                m={0}
                            >
                                {sample.DOB}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="body1"
                                align="left"
                                fontSize={10}
                                m={0}
                            >
                                {sample.Specimen}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                                fontSize={10}
                                m={0}
                            >
                                {sample.Container}
                            </Typography>
                        </Stack>
                        <Box width="100%" align="center">
                            <Barcode
                                format="CODE128B"
                                height={30}
                                width={.94}
                                margin={0}
                                marginBottom={0}
                                fontSize={10}
                                value={sample.Barcode}
                            />
                        </Box>
                        <Typography
                            variant="body1"
                            align="left"
                            fontSize={10}
                            m={0}
                        >
                            Collected: {sample.Collected}
                        </Typography>
                    </Box>
                </>
            ))
            }
        </div>
    );
});

export default function SampleBarcodes({ orderDetails }) {
    let bars = [];
    for (let sample of orderDetails.Samples) {
        console.log(sample)
        for (let i = 0; i < sample?.SpecimenType?.QTY; i++) {
            console.log("pushing")
            bars.push(<Barcode value={sample.Barcode}></Barcode>);
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <ComponentToPrint
                ref={componentRef}
                orderDetails={orderDetails}
            />
            <Button onClick={handlePrint}>Print</Button>
        </>
    );
}

export function BarcodeDialog({ orderDetails, open, setOpen }) {
    return (
        <Dialog open={open}>
            <DialogTitle>Barcodes</DialogTitle>
            <DialogContent>
                <SampleBarcodes orderDetails={orderDetails} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
